<template>
  <div>
    <!-- search input -->
    <section id="kb-category-search">
      <b-card
        no-body
        class="apps-market-bg text-center"
        :style="{backgroundImage: `url(${require('@/assets/images/banner/banner.png')})`}"
      >
        <b-card-body class="card-body">
          <h2 class="text-primary">
            {{ category.name }}
          </h2>
          <b-card-text class="mb-2">
            <span>{{ category.description }}</span>
          </b-card-text>

          <!--          &lt;!&ndash; form &ndash;&gt;-->
          <!--          <b-form class="kb-search-input">-->
          <!--            <b-input-group class="input-group-merge">-->
          <!--              <b-input-group-prepend is-text>-->
          <!--                <feather-icon icon="SearchIcon" />-->
          <!--              </b-input-group-prepend>-->
          <!--              <b-form-input-->
          <!--                id="searchbar"-->
          <!--                v-model="appsMarketSearchQuery"-->
          <!--                placeholder="Ask a question...."-->
          <!--              />-->
          <!--            </b-input-group>-->
          <!--          </b-form>-->
          <!--          &lt;!&ndash; form &ndash;&gt;-->
        </b-card-body>
      </b-card>
    </section>
    <!--/ search input -->

    <div id="apps-market-category">
      <b-row class="match-height">
        <b-col
          v-for="(data,index) in subcategories"
          :key="data.slug"
          :md="subcategories.length && (subcategories.length < 4) ? (12 / subcategories.length) : 4"
          sm="12"
        >
          <apps-subcategory-card
            :title="data.name"
            :icon="data.icon"
            :icon-color="data.iconColor"
            :apps="data.apps"
          />
        </b-col>

        <!-- no result found -->
        <b-col
          v-show="!subcategories.length"
          cols="12"
          class="text-center"
        >
          <h4 class="mt-4">
            {{ $t('modules.apps._messages.no_apps_message') }}
          </h4>
        </b-col>
        <!--/ no result found -->
      </b-row>
      <apps-announcements />
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BCardText,
} from 'bootstrap-vue'
import AppsSubcategoryCard from '@/views/models/apps/AppsSubcategoryCard.vue'
import store from '@/store'
import appCategoryStoreModule from '@/views/models/apps/appCategoryStoreModule'
import { onUnmounted, ref } from 'vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { catchException, toast } from '@core/utils/utils'
import router from '@/router'
import AppsAnnouncements from "@/views/models/apps/AppsAnnouncements.vue";

export default {
  components: {
    AppsAnnouncements,
    AppsSubcategoryCard,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
  },
  data() {
    return {
      appsMarketSearchQuery: '',
    }
  },
  computed: {
    filteredKB() {
      const appsMarketSearchQueryLower = this.appsMarketSearchQuery.toLowerCase()
      return this.category.apps.filter(item => item.title.toLowerCase().includes(appsMarketSearchQueryLower) || item.apps.filter(queObj => queObj.title.toLowerCase().includes(appsMarketSearchQueryLower)).length)
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'app_categories'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, appCategoryStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const category = ref({
      title: '',
      description: '',
      subcategories: [],
    })
    const subcategories = ref([])
    const isLoading = ref(false)
    // Use toast
    const { t } = useI18nUtils()

    store
      .dispatch(`${STORE_MODULE_NAME}/fetchOne`, router.currentRoute.params.category)
      .then(response => {
        category.value = response.data
        subcategories.value = response.sub_categories
      })
      .catch(e => {
        if (e?.response?.status === 404) {
          router.push({ name: 'apps-index' })
          return
        }
        catchException(e)
        toast(
          'danger',
          t('message.error_fetching_list'),
          null,
          'AlertTriangleIcon',
        )
      })
      .finally(() => {
        isLoading.value = false
      })

    return {
      category,
      subcategories,
      isLoading,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
</style>
