<template>
  <b-card
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>
        <feather-icon
          v-if="icon"
          :icon="icon"
          size="20"
          class="mr-50"
          :class="iconColor"
        />
        {{ title }} ({{ apps.length }})
      </b-card-title>
      <!--      <feather-icon-->
      <!--        icon="MoreVerticalIcon"-->
      <!--        size="18"-->
      <!--        class="cursor-pointer"-->
      <!--      />-->
    </b-card-header>

    <b-card-body>
      <div
        v-for="app in apps"
        :key="app.slug"
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-link :to="{ name: 'apps-app', params: { category: $route.params.category, app: app.slug } }">
              <b-avatar
                rounded
                size="70"
                :variant="'light'"
              >
                <b-img
                  :src="app.image"
                  width="70"
                  height="auto"
                />
              </b-avatar>
            </b-link>
          </b-media-aside>
          <b-media-body>
            <b-link :to="{ name: 'apps-app', params: { category: $route.params.category, app: app.slug, categoryName: app.category, appName: app.name } }">
              <h6 class="transaction-title">
                {{ app.name }}
              </h6>
              <small>{{ app.short_description }}</small>
              <p class="text-secondary"><small v-if="app.is_installed">
                <feather-icon icon="CheckCircleIcon" />
                {{ $t('modules.apps._messages.installed') }}
              </small></p>
            </b-link>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder"
          :class="app.installed ? 'text-danger':'text-success'"
        >

          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-success"
            class="btn-icon rounded-circle"
            :to="{ name: 'apps-app', params: { category: $route.params.category, app: app.slug } }"
          >
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>

        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BAvatar, BCardTitle, BCardHeader, BMedia, BMediaAside, BMediaBody, BButton, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BImg,
    BLink,
    BMediaBody,
    BMediaAside,
    BMedia,
    BCardHeader,
    BCardTitle,
    BAvatar,
    BButton,
    BCard,
    BCardBody,
  },
  directives: {
    Ripple,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: null,
    },
    iconColor: {
      type: String,
      default: null,
    },
    apps: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
</style>
